import * as React from 'react';
import styled from '../styles/styled';


interface Props {
  title: string;
  href?: string;
  onClick?: React.MouseEventHandler;
  type?: 'submit' | 'reset' | 'button';
}


interface State {
  hover: boolean;
  pressed: boolean;
}


export class PrimaryButton extends React.Component<Props, State> {

  constructor(props) {
    super(props);
    this.state = {
      hover: false,
      pressed: false,
    };
  }

  public render() {
    if (this.props.href) {
      return this.renderAnchor();
    }
    return this.renderButton();
  }

  private renderButton() {
    return (
      <Button type={this.props.type} onClick={this.props.onClick}>
        <span>{this.props.title}</span>
      </Button>
    );
  }

  private renderAnchor() {
    return (
      <Anchor href={this.props.href} onClick={this.props.onClick}>
        <span>{this.props.title}</span>
      </Anchor>
    );
  }

}


const Button = styled.button`
  display: inline-flex;
  flex-direction: row;
  text-align: center;
  align-items: center;
  box-shadow: -0px 7px 27px rgba(235,47,43,0.15);
  background: linear-gradient(90deg, ${(props) => props.theme.colors.primary.dark} 0%,
                                     ${(props) => props.theme.colors.primary.normal} 100%);
  color: ${(props) => props.theme.colors.primary.text};
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  border: none;
  line-height: 1em;

  width: 200px;
  height: 55px;
  border-radius: 55px;
  transition: background-color;
  padding: 0;

  cursor: pointer;
  user-select: none;

  form & {
    margin-top: 2rem;
    background: white;
    color: ${(props) => props.theme.colors.primary.normal};
    white-space: nowrap;
  }

  header & {
    width: 90px;
    height: 25px;
    border-radius: 25px;
    font-size: 75%;
  }

  header .dark & {
    background-color: transparent;
    border: 1.5px solid ${(props) => props.theme.colors.primary.normal};
    background-image: none;
    box-sizing: border-box;
  }

  & span {
    flex-grow: 1;
  }

  &:hover {
    text-decoration: underline;
  }
`;

const Anchor = Button.withComponent('a');
