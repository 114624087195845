import * as React from 'react';
import styled from '../styles/styled';
import { Logo } from './logo';


export class Footer extends React.Component {

  public static defaultProps = {
    backgroundColor: 'primary',
  };

  public render() {
    return (
      <Container>
        <Content>
          <Logo color='white'/>
          <div style={{flexGrow: 1}}/>
          <p>&copy; Outfox, inc. 2019. All rights reserved.</p>
        </Content>
      </Container>
    );
  }

}


const Container = styled.footer`
  position: relative;
  left: 0;
  right: 0;
  margin: auto;
  overflow: hidden;
  padding: calc(${(props) => props.theme.sizes.desktop.content.margin} * 2) 0;
  background-color: ${(props) => props.theme.colors.secondary.normal};
  color: ${(props) => props.theme.colors.secondary.text};
`;
Container.displayName = 'Container';


const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: end;
  width: ${(props) => props.theme.sizes.desktop.width};
  height: ${(props) => props.theme.sizes.desktop.footer.height};
  padding: ${(props) => props.theme.sizes.desktop.content.margin};
  margin: auto;
  position: relative;
  box-sizing: border-box;
`;
Container.displayName = 'Content';
