import { MouseEventHandler } from 'react';
import * as React from 'react';
import * as LogoText from '../images/logo-text.inline.svg';
import * as LogoImage from '../images/logo.inline.svg';
import styled from '../styles/styled';


interface Props {
  showName?: boolean;
  color: string;
  onClick?: MouseEventHandler;
}


interface ContainerProps {
  color: string;
}


const Container = styled.div<ContainerProps>`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  &.clickable {
    cursor: pointer;
  }
  & path {
    fill: ${(props) => props.color};
  }
`;

const Image = styled(LogoImage)`
  height: 100%;
  margin-right: 10px;
`;

const Text = styled(LogoText)`
  height: 65%;
`;


export class Logo extends React.Component<Props> {

  public static defaultProps = {
    showName: true,
  };

  public render() {
    return (
      <Container color={this.props.color}
                 onClick={this.props.onClick}
                 className={this.props.onClick ? 'clickable' : ''}>
        <Image/>
        {this.props.showName && <Text/>}
      </Container>
    );
  }

}
