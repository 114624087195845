import hexToRgba from 'hex-to-rgba';
import * as React from 'react';
import { sectionsQuery } from '../hooks/sections.query';
import styled from '../styles/styled';
import { HeaderNav, NavItem } from './header-nav';
import { Logo } from './logo';
import { PrimaryButton } from './primary-button';
import { SignUpModalForm } from './sign-up-modal-form';


export const Header: React.FunctionComponent = () => {
  const navItems = sectionsQuery().map((section) => {
    return { ...section, link: `#${section.name}` };
  });
  return (
    <Container>
      <HeaderContent items={navItems}/>
    </Container>
  );
};


interface HeaderContentProps {
  items: NavItem[];
}


interface HeaderContentState {
  backgroundVisible: boolean;
  currentSectionId?: string;
  signUpFormVisible: boolean;
}


class HeaderContent extends React.Component<HeaderContentProps, HeaderContentState> {

  private header?: HTMLElement;
  private sections?: NodeListOf<HTMLElement>;

  constructor(props) {
    super(props);
    this.state = {
      backgroundVisible: false,
      signUpFormVisible: false,
    };
  }

  public componentDidMount(): void {
    window.addEventListener('scroll', () => this.scrollChanged());
    this.header = document.querySelector('header');
    this.sections = document.querySelectorAll('section[id]');
  }

  public componentWillUnmount(): void {
    window.removeEventListener('scroll', () => this.scrollChanged());
  }

  public render() {
    return (
      <Content className={this.state.backgroundVisible ? 'dark' : ''}>
        <Logo color='white' onClick={() => window.location.hash = '#heroes'}/>
        <HeaderNav items={this.props.items} activeId={this.state.currentSectionId}/>
        <div style={{ flexGrow: 1 }}/>
        <PrimaryButton title='Sign Up'
                       onClick={() => this.setState({ signUpFormVisible: true })}/>
        <SignUpModalForm isOpen={this.state.signUpFormVisible}
                         onRequestClose={() => this.setState({ signUpFormVisible: false })}/>
      </Content>
    );
  }

  private scrollChanged() {

    const offset = window.pageYOffset;
    const forwardEnable = this.header.offsetHeight * 0.66;

    let foundSectionId: string | undefined;
    this.sections.forEach((section) => {
      if (
        foundSectionId === undefined &&
        offset > (section.offsetTop - forwardEnable) &&
        offset < (section.offsetTop + section.offsetHeight - forwardEnable)
      ) {
        foundSectionId = section.id;
      }
    });

    this.setState({
      backgroundVisible: window.pageYOffset > 20,
      currentSectionId: foundSectionId,
    });
  }

}


const Container = styled.header`
  background: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
`;


const Content = styled.div`
  position: relative;
  width: ${(props) => props.theme.sizes.desktop.width};
  height: ${(props) => props.theme.sizes.desktop.header.height};
  padding-left: ${(props) => props.theme.sizes.desktop.content.margin};
  padding-right: ${(props) => props.theme.sizes.desktop.content.margin};
  padding-top: ${(props) => props.theme.sizes.desktop.header.padding.top};
  padding-bottom: ${(props) => props.theme.sizes.desktop.header.padding.bottom};
  margin: auto;
  box-sizing: content-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  transition-property: padding-top, background-color, opacity;
  transition-timing-function: ease-in-out;
  transition-duration: 300ms;

  &.dark {
    background-color: ${(props) => hexToRgba(props.theme.colors.secondary.dark, 0.75)};
    border-radius: 0 0 5px 5px;
    padding: 3px;

    &:hover {
      background-color: ${(props) => hexToRgba(props.theme.colors.secondary.dark, 0.9)};
      transition-duration: 250ms;
    }
  }
`;


