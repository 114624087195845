import addToMailchimp from 'gatsby-plugin-mailchimp';
import { FormEvent } from 'react';
import * as React from 'react';
import * as ReactModal from 'react-modal';
import contactUsBg from '../images/contact-bg.png';
import styled from '../styles/styled';
import { LabeledInput } from './labeled-input';
import { PrimaryButton } from './primary-button';


interface Props {
  isOpen: boolean;
  onRequestClose: () => void;
}

interface State {
  email: string;
  completed: boolean;
  statusMessage: string;
}


export class SignUpModalForm extends React.Component<Props, State> {

  private readonly form: React.RefObject<HTMLFormElement>;

  constructor(props) {
    super(props);
    this.form = React.createRef();
    this.state = {
      email: '',
      completed: false,
      statusMessage: '',
    };
  }

  public render() {
    return (
      <ReactModal isOpen={this.props.isOpen}
                  shouldCloseOnOverlayClick={true}
                  onRequestClose={this.props.onRequestClose}
                  style={ModalStyles}>
        <Container>
          <h1>Outfox <code>BETA</code> Sign-Up</h1>
          { !this.state.completed &&
            <p>
              Outfox is currently in <code>BETA</code>, provide us your email and
              we'll give you access as soon as space is available.
            </p>
          }
          { !this.state.completed &&
            <Form onSubmit={(e) => this.handleSubmit(e)} ref={this.form}>
              <LabeledInput type='email' name='email'
                            placeholder='E-mail'
                            color='white'
                            value={this.state.email}
                            onChange={(event) => this.setState({ email: event.target.value })}/>
              <p className='status' dangerouslySetInnerHTML={{__html: this.state.statusMessage}}/>
              <PrimaryButton title='Sign Up' type='submit'/>
            </Form>
          }
          { this.state.completed &&
            <h3>You've been added to our <code>BETA</code> list and we'll contact you soon.</h3>
          }
          { this.state.completed &&
            <a onClick={() => this.reset()}>Add another?</a>
          }
        </Container>
      </ReactModal>
    );
  }

  private reset() {
    this.setState({
      email: '',
      statusMessage: '',
      completed: false,
    });
  }

  private async handleSubmit(event: FormEvent) {
    event.preventDefault();

    const add = await addToMailchimp(this.state.email);

    if (add.result === 'error') {
      this.setState({ statusMessage: add.msg });
      return;
    }

    this.setState({ completed: true });
  }

}


const ModalStyles = {
  overlay: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '5em',
  },
  content: {
    position: 'unset',
    top: 'unset',
    bottom: 'unset',
    left: 'unset',
    right: 'unset',
    background: 'none',
    border: 'none',
    padding: 'none',
    borderRadius: '6px',
  },
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  color: ${(props) => props.theme.colors.primary.text};
  margin: 0;
  padding: 2rem;
  position: relative;
  background-image: url("${contactUsBg}");
  background-position: top center;

  code {
    font-size: inherit;
    color: ${(props) => props.theme.colors.secondary.normal};
    font-weight: normal;
  }
  p {
    font-size: larger;
  }
  a {
    color: ${(props) => props.theme.colors.secondary.normal};
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  opacity: 0;
  transition: opacity 250ms ease-in-out;

  .ReactModal__Content--after-open & {
    opacity: 1;
  }
  .ReactModal__Content--before-close & {
    opacity: 0;
  }
`;

const Form = styled.form`
  width: 50%;

  text-align: center;

  & > label {
    margin-top: 1.5rem;
    font-size: 1.5rem;

    & > textarea {
      height: 5em;
    }
  }

  & a {
    margin-top: 2rem;
    background: white;
    color: ${(props) => props.theme.colors.primary.normal};
    white-space: nowrap;
  }

  p.status {
    font-size: smaller;
    margin-top: 1rem;
  }
`;
