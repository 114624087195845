import * as React from 'react';
import styled from '../styles/styled';


export interface NavItem {
  id: string;
  title: string;
  link: string;
}


interface Props {
  items: NavItem[];
  activeId?: string;
}


export const Container = styled.nav`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
  margin-left: 2rem;
  height: 130%;

  & a {
    color: white;
    text-decoration: none;
    border: none;
    margin: 1rem;
    border-bottom: 2px transparent solid;

    &:hover, &.active {
      border-bottom: 2px solid;
      border-color: ${(props) => props.theme.colors.primary.normal};
    }
  }
`;

export const Anchor = styled.a`
`;


export class HeaderNav extends React.Component<Props> {

  constructor(props) {
    super(props);
  }


  public render() {
    return (
      <Container>
        {this.createItems()}
      </Container>
    );
  }

  private createItems() {

    const items = [];
    const activeId = `#${this.props.activeId || ''}`;

    for (const item of this.props.items) {
      items.push(
        <Anchor key={item.id}
                href={item.link}
                className={item.link === activeId ? 'active' : ''}>
          {item.title}
        </Anchor>,
      );
    }

    return items;
  }

}
