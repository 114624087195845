import * as React from 'react';
import styled from '../styles/styled';
import { overlay } from '../styles/utils';


interface Props {
  type: string;
  name: string;
  value: string;
  onChange?: (ChangeEvent) => void;
  color: string;
  placeholder?: string;
  children?: any;
  required?: boolean;
}


interface State {
  focused: boolean;
  valued: boolean;
}


export class LabeledInput extends React.Component<Props, State> {

  constructor(props) {
    super(props);
    this.state = {
      focused: false,
      valued: !!props.value,
    };
  }

  public render() {
    return (
      <Container className={this.classNames()}>
        {this.props.placeholder && <Placeholder>{this.props.placeholder}</Placeholder>}
        {this.props.type !== 'textarea' &&
        <Input type={this.props.type}
                      name={this.props.name}
                      value={this.props.value}
                      color={this.props.color}
                      required={this.props.required}
                      onFocus={() => this.handleFocus()}
                      onBlur={() => this.handleBlur()}
                      onChange={(e) => this.handleChange(e)}>
          {this.props.children}
        </Input>
        }
        {this.props.type === 'textarea' &&
        <Textarea name={this.props.name}
                  value={this.props.value}
                  color={this.props.color}
                  required={this.props.required}
                  onFocus={() => this.handleFocus()}
                  onBlur={() => this.handleBlur()}
                  onChange={(e) => this.handleChange(e)}>
          {this.props.children}
        </Textarea>
        }
      </Container>
    );
  }

  private classNames() {
    const classes = [];
    if (this.state.focused) {
      classes.push('has-focus');
    }
    if (this.state.valued) {
      classes.push('has-value');
    }
    return classes.join(' ');
  }

  private handleChange(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    this.setState({
      focused: true,
      valued: e.target.value.trim() !== '',
    });
    if (this.props.onChange) {
      this.props.onChange(e);
    }
  }

  private handleFocus() {
    this.setState({ focused: true });
  }

  private handleBlur() {
    this.setState({ focused: false });
  }

}


export const Container = styled.label`
  position: relative;
  background: none;
  display: block;
`;

export const Placeholder = styled.div`
  ${overlay};
  background: none;
  transition: ease-in-out 300ms;
  transition-property: font-size, padding-top;
  opacity: .7;
  user-select: none;

  padding-top: .75em;
  label.has-value > &, label.has-focus > & {
    font-size: .65em;
    padding-top: 0;
  }
`;

export const Input = styled.input`
  margin: 0;
  padding-top: .75em;
  border: none;
  border-bottom: 1px solid ${(props) => props.color};
  color: ${(props) => props.color};
  outline: none;
  background: none;
  width: 100%;
`;

export const Textarea = styled.textarea`
  margin: 0;
  padding-top: .75em;
  border: none;
  border-bottom: 1px solid ${(props) => props.color};
  color: ${(props) => props.color};
  outline: none;
  background: none;
  width: 100%;
`;
