import { ThemeProvider } from 'emotion-theming';
import * as React from 'react';
import { Component } from 'react';
import Helmet from 'react-helmet';
import { THEME } from '../styles/theme';
import { Footer } from './footer';
import { Header } from './header';
import { SEO } from './seo';


interface Props {
  title: string;
  children: any;
}


export class Layout extends Component<Props> {

  public render() {
    return (
      <ThemeProvider theme={THEME}>
        <Helmet>
          <meta name='viewport' content='width=device-width, shrink-to-fit=yes, width=1250'/>
        </Helmet>
        <SEO title={this.props.title}/>
        <main>{this.props.children}</main>
        <Header/>
        <Footer>
          © {new Date().getFullYear()}, Built with
          {` `}
          <a href='https://www.gatsbyjs.org'>Gatsby</a>
        </Footer>
      </ThemeProvider>
    );
  }

}
