export interface ThemeColor {
  normal: string;
  text: string;
  dark?: string;
}


export interface Theme {
  colors: { [key: string]: ThemeColor; };
  [key: string]: any;
}


export const THEME: Theme = {
  colors: {
    offWhite: {
      normal: '#FFF4E0',
      dark: '#FFF4E0',
      text: '#012E49',
    },
    white: {
      normal: '#FFF',
      dark: '#FFF',
      text: '#012E49',
    },
    primary: {
      normal: '#F37321',
      dark: '#E92B2D',
      text: '#fff',
    },
    primaryTint: {
      normal: '#FEFCF8',
      dark: '#FEFCF8',
      text: '#012E49',
    },
    secondary: {
      normal: '#033B5B',
      dark: '#012E49',
      text: '#fff',
    },
    secondaryTint: {
      normal: '#F2FAFF',
      dark: '#F2FAFF',
      text: '#012E49',
    },
  },
  sizes: {
    desktop: {
      width: `1200px`,
      content: {
        margin: '15px',
      },
      hero: {
        height: `935px`,
      },
      header: {
        height: '32px',
        padding: {
          top: '40px',
          bottom: '10px',
        },
      },
      footer: {
        height: '55px',
      },
    },
  },
};
