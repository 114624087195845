import { css } from '@emotion/core';
import styled from './styled';


export const overlay = css`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`;
