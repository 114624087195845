import { graphql, useStaticQuery } from 'gatsby';
import { Contact } from '../components/contact-us-section';
import { Feature } from '../components/feature-section';
import { Solution } from '../components/solutions-section';
import { Section } from '../pages';


export const sectionsQuery = (): Section[] => {
  const { allSectionsYaml } = useStaticQuery(
    graphql`
        query {
          allSectionsYaml {
            nodes {
              attention
              id
              name
              title
            }
          }
        }
      `,
  );

  return allSectionsYaml.nodes;
};
